<template>
  <div class="player-setup-header">
    <div class="left-side">
      <Icon
        v-if="showBackBtn"
        @click="goBack"
        class="go-back-icon"
        name="arrow-right"
      />
      <div class="event-info">
        <div
          v-if="eventName"
          class="event-name"
          :class="{ 'active': eventSearchActive }"
          @click="eventSearchToggle"
        >
          <h6>
            {{ eventName }}
          </h6>
          <Icon
            class="open-event-list-icon"
            name="chevron-down"
          />
        </div>
        <div
          v-if="dateTime"
          class="date-time-info"
        >
          <p>
            {{ dateTime }}
          </p>
        </div>
      </div>
      <PlayerSetupEventSearch
        v-if="eventSearchActive"
        ref="searchEventsRootRef"
        @eventSelected="eventSelected"
      />
    </div>
    <div class="right-side">
      <Button
        :icon="isEventSuspended ? 'play-circle' : 'pause-circle'"
        :variant="isEventSuspended ? 'secondary' : 'danger'"
        class="suspend-event-btn"
        :class="{ 'event-suspended': isEventSuspended }"
        @click="toggleEventSuspend(!isEventSuspended)"
        :loading="isEventSuspendLoading"
      >
        {{ isEventSuspended ? 'Unsuspend' : 'Suspend event' }}
      </Button>
      <Button
        class="go-to-trading-ui-btn"
        @click="goToTradingUi"
        variant="secondary"
        :icon="'link-external'"
      >
        Trade event
      </Button>
      <Button
        class="publish-btn"
        @click="onPublish"
        variant="primary"
        :icon="'arrow-up'"
        :disabled="!submitBtnActive"
      >
        Publish
      </Button>
      <!-- <Dropdown
        icon="dots-vertical"
        hide-chevron
        right
      >
        <DropdownItem
          clickable
          @click="goToTradingUi"
        >
          <Icon :name="'link-external'" />
          <span>Trade event</span>
        </DropdownItem>
      </Dropdown> -->
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import {
  split, replace, filter, includes,
} from 'lodash';
import { onClickOutside } from '@vueuse/core';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
// import Dropdown from '@/components/common/Dropdown';
// import DropdownItem from '@/components/common/DropdownItem';
import PlayerSetupEventSearch from './PlayerSetupEventSearch';

export default {
  emits: ['goBack', 'onTradingUIRoute'],
  components: {
    Icon,
    Button,
    // Dropdown,
    // DropdownItem,
    PlayerSetupEventSearch,
  },
  props: {
    allowRouteChange: {
      type: Boolean,
      default: true,
    },
    showBackBtn: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const data = computed(() => store.getters.playerSetupData || {});
    const isEventSuspended = computed(() => data.value.isSuspended);
    const isEventSuspendLoading = computed(() => store.getters.paramsManagerEventSuspendLoading);
    const eventSearchActive = computed(() => store.getters.playerSetupEventSearchActive);
    const searchEventsRootRef = ref(null);
    const inferBtnActive = computed(() => store.getters.playerSetupInferActive);
    const submitBtnActive = computed(() => store.getters.isPlayerSetupSubmitEnabled && !inferBtnActive.value);
    const eventName = computed(() => {
      const teamAName = data.value?.teamA?.name;
      const teamBName = data.value?.teamB?.name;
      const teamDivider = data.value.isUSAView ? '@' : 'v';
      return `${teamAName} ${teamDivider} ${teamBName}`;
    });
    const dateTime = computed(() => {
      const { startsAt } = data.value || {};
      const formattedStartsAt = replace(
        startsAt ? format(zonedTimeToUtc(startsAt, 'UTC'), 'MMM d, yyyy @ hh:mm a') : '@',
        `, ${new Date().getFullYear()}`,
        '',
      );
      const [date, time] = filter(split(formattedStartsAt, '@'), Boolean);
      return date && time ? `${date} at ${time}` : null;
    });
    const eventSearchToggle = () => {
      store.dispatch('setIsPlayerSetupEventSearchActive', !eventSearchActive.value);
    };

    onClickOutside(searchEventsRootRef, (event) => {
      // Solution for when clicking on element which opens the event search popup
      let currentEl = event.target;
      const els = [];
      while (currentEl) {
        els.push(currentEl.className);
        currentEl = currentEl.parentNode;
      }
      if (includes(els, 'event-info')) return;
      eventSearchToggle();
    });

    const eventSelected = (eventId) => {
      router.push({
        name: 'params-setup',
        params: {
          eventId,
        },
      });
    };

    const goBack = () => emit('goBack');

    const route = useRoute();
    const goToTradingUi = () => {
      if (!props.allowRouteChange) {
        emit('onTradingUIRoute');
      }
      router.push({
        name: 'trading-ui',
        params: {
          eventId: route.params.eventId,
        },
      });
    };

    const onPublish = () => {
      store.dispatch('submitPlayerSetup');
    };

    const toggleEventSuspend = (isSuspended) => {
      store.dispatch('toggleParamsSetupEventSuspend', { eventId: data.value.eventId, isSuspended });
    };

    return {
      data,
      eventName,
      dateTime,
      eventSearchActive,
      searchEventsRootRef,
      eventSearchToggle,
      eventSelected,
      goBack,
      goToTradingUi,
      submitBtnActive,
      onPublish,
      isEventSuspended,
      toggleEventSuspend,
      isEventSuspendLoading,
    };
  },
};
</script>

<style lang="scss">
.player-setup-header {
  height: 58px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid $gray400;

  .left-side {
    display: flex;
    position: relative;
    .icon {
      width: 16px;
      height: 16px;
    }
    .go-back-icon {
      margin-top: 5px;
      transform: rotate(180deg);
      margin-right: 16px;
      cursor: pointer;
    }
    .event-info {
      .event-name {
        display: flex;
        cursor: pointer;
        padding: 0 4px;
        background-color: $gray400;
        border-radius: 4px;
        margin-bottom: 4px;
        transition: background-color .3s;

        .open-event-list-icon {
          margin-top: 5px;
          margin-left: 10px;
          cursor: pointer;
          stroke: $black;
          transition: transform .3s;
        }

        &.active {
          background-color: transparent;
          .open-event-list-icon {
            transform: rotate(180deg);
          }
        }
      }
      .date-time-info {
        font-size: 14px;
        line-height: 17px;
        color: #a9a9a9;
      }
    }
    .player-setup-event-search {
      position: absolute;
      top: 30px;
      left: 30px;
      z-index: 200;
    }
  }

  .right-side {
    display: flex;
    height: 100%;
    align-items: center;
    gap: 8px;
    .suspend-event-btn {
      .icon svg path {
        fill: $black;
      }
      &:not(.event-suspended) {
        .icon svg path {
          fill: $white;
        }
      }
      &.button--loading {
        .icon svg path {
          fill: unset;
          stroke: $black;
        }
      }
    }

    .publish-btn {
      .icon {
        svg path {
          fill: $white;
          stroke: none;
        }
      }
      .go-to-trading-ui-btn {
        .icon {
          svg path {
            fill: $black;
            stroke: none;
          }
        }
      }
      &.button--disabled {
        .icon {
          svg path {
            fill: $gray700;
          }
        }
      }
    }
    .dropdown-item {
      gap: 5px;
    }

    .player-setup-header-projections-available {
      height: 100%;
      display: flex;
      align-items: center;
      .projections-available-indicator {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #FFC531;
        position: relative;
        &.available {
          background-color: $success50;
        }
        &::before{
          content: '';
          width: 10px;
          height: 10px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          border: 2px solid $white;
          background-color: transparent;
        }
      }
    }

    .player-projections-button {
      &.disabled {
        opacity: 0.5;
      }
    }
  }
}
</style>
